export const SidebarMenuData = [
  {
    menuId: 'home',
    name: '首页',
    theUri: '/home',
  },
  {
    menuId: '2',
    name: '业务办理',
    children: [
      {
        menuId: 'sparePartsMall',
        name: '零配件销售',
        theUri: '/sparePartsMall/sparePartsMall',
      },
      {
        menuId: 'recycling-waste',
        name: '废旧物资回收',
        theUri: '/recycling-waste/list',
      },
      {
        menuId: 'used-car-trading',
        name: '二手车交易',
        theUri: '/used-car-trading/image-list',
      },
      {
        menuId: 'new-car-trading',
        name: '新车交易',
        theUri: '/new-car-trading/image-list',
      },
      {
        menuId: 'bulkCommodity',
        name: '大宗商品销售',
        theUri: '/bulkCommodity/bulkCommodity',
      },
    ],
  },
  {
    menuId: 'corporateNews',
    name: '公司要闻',
    theUri: '/carsite/same-list/corporateNews/list?index=2',
  },
  {
    menuId: 'grassrootsDynamic',
    name: '党的建设',
    theUri: '/carsite/same-list/grassrootsDynamic/list?index=3',
  },
  {
    menuId: 'companyCulture',
    name: '企业文化',
    theUri: '/carsite/same-list/companyCulture/list?index=6',
  },
  {
    menuId: 'aboutUs',
    name: '关于我们',
    theUri: '/carsite/same-list/aboutUs/list?index=8',
  },
  {
    menuId: '3',
    name: '员工风采',
    children: [
      {
        menuId: 'videoCenter',
        name: '视频中心',
        theUri: '/carsite/same-list/videoCenter/list?index=4',
      },
      {
        menuId: 'staffStyle',
        name: '文化园地',
        theUri: '/carsite/same-list/staffStyle/list?index=4',
      },
    ],
  },
];
