<template>
  <div class="sidebar">
    <el-menu
      mode="horizontal" 
      :default-active="index"
      :unique-opened="true" 
      :collapse-transition="false"
      class="sidebar-menu"
      @select="handleSelect"
    >
      <sidebar-item
        v-for="route in SidebarMenuData"
        :key="route.menuId"
        :item="route"
        :is-round-dots="!!route.children"
        :base-path="route.theUri"
      />
    </el-menu>
  </div>
</template>

<script>
import SidebarItem from "./sidebar-item.vue";
import { SidebarMenuData } from "./mock";
export default {
  name: "adressMaintain",
  components: {
    SidebarItem,
  },
  props: {
    index: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      SidebarMenuData,
    };
  },
  methods: {
    handleSelect(key, keyPath) {
      // console.log(key, keyPath, 'key, keyPath')
    },
  },
};
</script>
<style lang="less">
/deep/ .el-menu--horizontal{
  ul li {
    list-style: none;
  }
}
.sidebar {
  >.el-menu--horizontal {
    border-bottom-width: 3px;
    width: 93%;
		max-width: 1200px;
    >a{
      float: left;
    }
  }
  /*去除导航菜单下划白线*/
  .el-menu.el-menu--horizontal{
    border: none;
  }
  >.el-menu--horizontal >.el-menu-item,
  >.el-menu--horizontal>.el-submenu{
    color: #FFFFFF;
    width: 14%;
    text-align: center;
    padding: 0;
    >a{
      display: inline-block;
      width: 100%;
      height: 56px;
      line-height: 54px;
      text-decoration: none;
      vertical-align: initial;
    }
  }
  >.el-menu {
    background: #0054a7;
  }
  >.el-menu--horizontal > .el-menu-item {
    height: 56px;
    line-height: 54px;
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
  }
  >.el-menu--horizontal > .el-submenu .el-submenu__title {
    height: 56px;
    line-height: 54px;
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
  }
  >.el-menu--horizontal>.el-submenu.is-active .el-submenu__title{
    border-bottom-color: rgb(255 102 0) !important;
  }
  >.el-menu--horizontal >.el-submenu.is-active,
  >.el-menu--horizontal >.el-submenu.is-opened,
  >.el-menu--horizontal >.el-menu-item:not(.is-disabled):focus, 
  >.el-menu--horizontal >.el-menu-item:not(.is-disabled):hover, 
  >.el-menu--horizontal >.el-submenu .el-submenu__title:hover,
  >.el-menu--horizontal >.el-menu:hover,
  >.el-menu--horizontal >.el-menu-item.is-active, 
  >.el-menu--horizontal >.el-menu.is-opened{
    background: rgb(255 102 0) !important;
    border: none;
    color: #FFFFFF;
    >.el-submenu__title{
      font-size: 18px;
      background: rgb(255 102 0) !important;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #FFFFFF;
    }
    >.el-menu--horizontal{
        >.el-menu>.el-menu-item.nest-menu{
          text-align: left;
          height: 40px;
          line-height: 40px;
          padding: 0;
          >a{
            text-decoration: none;
            padding-left: 6%;
            padding-right: 4%;
            display: inline-block;
            box-sizing: border-box;
            width: 100%;
            color: #333333;
            height: 100%;
          }
          >a>span{
            padding-left: 6%;
            padding-right: 4%;
            font-size: 16px;
            border-radius: 8px;
            font-family: Source Han Sans CN;
            font-weight: 400;
          }
          &.is-active>a>span,&.is-opened>a>span{
            width: 90%;
            display: inline-block;
            background: #DAE3F4;
            color: #032E81;
            line-height: 35px;
          }
        }
      }
  }
  >.el-submenu__icon-arrow.el-icon-arrow-down,.el-submenu__title .el-submenu__icon-arrow.el-icon-arrow-down{
    display: none;
  }
  >.el-menu--horizontal .el-menu-overflow-item .el-menu-title-content {
    color: #fff;
    display: inline-block;
    padding: 0 21px;
    a {
      color: #FFFFFF;
    }
  }
  >.el-menu--horizontal:not(.el-menu-dark) > .el-menu-item:hover::after,
  >.el-menu--horizontal:not(.el-menu-dark) > .el-menu-submenu:hover::after,
  >.el-menu--horizontal:not(.el-menu-dark) > .el-menu-item-active::after,
  >.el-menu--horizontal:not(.el-menu-dark) > .el-menu-submenu-active::after,
  >.el-menu--horizontal:not(.el-menu-dark) > .el-menu-item-open::after,
  >.el-menu--horizontal:not(.el-menu-dark) > .el-menu-submenu-open::after,
  >.el-menu--horizontal:not(.el-menu-dark) > .el-menu-item-selected::after,
  >.el-menu--horizontal:not(.el-menu-dark) > .el-menu-submenu-selected::after {
    border-bottom-color: #fff;
  }
}
</style>
<style lang="less" scoped>
.sidebar {
  width: 100%;
  height: 56px;
  line-height: 56px;
  background: #0054a7;
  .sidebar-menu {
    margin: 0 auto;
  }
}
</style>
