<template>
  <div class="app-wrapper">
    <Title></Title>
    <div class="main-container" v-loading="loading">
      <div class="router-content" id="mainContainer">
        <keep-alive v-if="$route.meta && $route.meta.keepAlive">
          <router-view></router-view>
        </keep-alive>
        <router-view v-else></router-view>
        <HomeFooter></HomeFooter>
      </div>
    </div>
    <div
      class="rightImg"
      style="position: fixed;right: 1%;top: 45%;z-index:9999"
    >
      <div
        slot="reference"
        style="width: 79px;height: 79px;display:flex;align-items:center;justify-content:center;text-align:center;border-radius: 5px;cursor: pointer;position: relative;"
      >
        <el-tooltip
          class="item"
          effect="light"
          content="点击联系电话"
          placement="bottom"
        >
          <img
            @click="onClikcPhone"
            src="/img/phone.png"
            style="width:79px;height:79px;"
          />
        </el-tooltip>
      </div>
    </div>

    <!-- <div
        slot="reference"
        style="width: 60px;height: 60px;display:flex;align-items:center;justify-content:center;text-align:center;background: #ffffff;border-radius: 5px;box-shadow: 0px 0px 25px 0px rgba(32, 90, 196, 0.14);cursor: pointer;position: relative;margin-bottom: 10px;"
      >
        <el-tooltip
          class="item"
          effect="light"
          content="点击联系QQ客服"
          placement="left"
        >
          <a
            target="_blank"
            href="http://wpa.qq.com/msgrd?v=3&uin=3012734404&site=qq&menu=yes"
          >
            <img
              src="/img/QQ客服.png"
              style="width:60px;height:60px;margin-bottom: 10px;"
            />
          </a>
        </el-tooltip>
      </div>
      <el-popover placement="right" width="200" trigger="click">
        <div
          style="width: 180px;margin:0 auto;text-align:center;"
          v-for="item in homeData.weChats"
          :key="item.id"
        >
          <div style="font-size:18px;">{{ item.name }}</div>
          <img style="width: 180px;height: 180px;" :src="item.path" alt="" />
        </div>
        <div
          slot="reference"
          style="width: 60px;height: 60px;display:flex;align-items:center;justify-content:center;text-align:center;background: #ffffff;border-radius: 5px;box-shadow: 0px 0px 25px 0px rgba(32, 90, 196, 0.14);cursor: pointer;position: relative;"
        >
          <el-tooltip
            class="item"
            effect="light"
            content="点击联系客服"
            placement="left"
          >
            <div style="width: 100%;">
              <img
                style="width: 33px;height: 36px;"
                src="/img/right2.png"
                alt=""
              />
            </div>
          </el-tooltip>
        </div>
      </el-popover>
      <el-popover
        placement="left"
        popper-class="complaSugges-popper"
        width="300"
        v-model="complaSuggesVisible"
        @doClose="showComplaSuggesVisible"
        @show="popoverPublic"
        @hide="popoverPublic"
        trigger="manual"
      >
        <div class="complaSugges">
          <div class="complaSugges-title">投诉建议</div>
          <el-form class="complaSugges-form" ref="complaSuggesForm" :model="complaSuggesForm">
            <el-form-item
              :rules="[{ required: true, message: '类型' }]"
              prop="SuggestionsType"
              label="类型:"
              label-width="60px"
            >
              <el-radio-group v-model="complaSuggesForm.SuggestionsType">
                <el-radio :label="0">投诉</el-radio>
                <el-radio :label="1">建议</el-radio>
              </el-radio-group>
            </el-form-item>
            <div style="padding-bottom: 20px;">
              <el-form-item
                :rules="[{ required: true, message: '请输入主题' }]"
                prop="Title"
                class="complaSuggesTitle"
                label="主题:"
                label-width="60px"
              >
                <el-input
                  placeholder="请输入主题"
                  v-model="complaSuggesForm.Title"
                ></el-input>
              </el-form-item>
            </div>
            <div style="padding-bottom: 10px;">
              <el-form-item
                :rules="[{ required: true, message: '内容不能为空' }]"
                prop="Content"
                class="complaSuggesTextarea"
                label="内容:"
                label-width="60px"
              >
                <el-input
                  :row="7"
                  type="textarea"
                  placeholder="请输入内容"
                  v-model="complaSuggesForm.Content"
                ></el-input>
              </el-form-item>
            </div>
            <el-form-item
              class="complaSuggesUpload"
              prop="SuggestionsToFiles"
              :rules="[{ required: true, message: '请上传相关图片', trigger: 'change' }]"
              label="图片"
              label-width="60px"
            >
							<el-upload
								:multiple="false"
								:class="{ hideBtn: noneBtnImg }"
								class="avatar-uploader"
								:action="uploadurl"
								ref="avatar-uploader"
								list-type="picture-card"
								:limit="limitCountImg"
								:file-list="fileList"
								:on-success="handleAvatarSuccess"
								:on-change="handleAvatarSuccessChange"
								:before-upload="beforeAvatarUpload"
							>
								<div slot="file" slot-scope="{ file }">
									<img
										class="el-upload-list__item-thumbnail"
										:src="file.url"
										alt=""
									/>
									<span class="el-upload-list__item-actions">
										<span
											class="el-upload-list__item-preview"
											@click="handlePictureCardPreview(file)"
										>
											<i class="el-icon-zoom-in"></i>
										</span>
										<span
											v-if="!disabled"
											class="el-upload-list__item-delete"
											@click="handleRemove(file)"
										>
											<i class="el-icon-delete"></i>
										</span>
									</span>
								</div>
								<i class="el-icon-plus avatar-uploader-icon"></i>
							</el-upload>
            </el-form-item>
            <el-form-item class="complaSuggesBtn">
              <el-button @click="suggesFormSubmit">提交</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div
          @click.stop="showComplaSuggesVisible"
          slot="reference"
          style="width: 60px;height: 60px;display:flex;align-items:center;justify-content:center;text-align:center;background: #ffffff;border-radius: 5px;box-shadow: 0px 0px 25px 0px rgba(32, 90, 196, 0.14);margin-top: 10px;cursor: pointer;position: relative;"
        >
          <el-tooltip
            class="item"
            effect="light"
            content="点击提交投诉建议"
            placement="left"
          >
            <div style="width: 100%;">
              <img
                style="width: 33px;height: 36px;"
                src="/img/right1.png"
                alt=""
              />
            </div>
          </el-tooltip>
        </div>
      </el-popover> -->
    <!-- </div> -->
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
    <el-dialog
      :visible.sync="phoneVisible" 
      width="560px"
      custom-class="phone-dialog"
    >
      <div class="phone">
        <div class="phone-line" v-for="item in YwhzrxData" :key="item.id">
          <span class="phone-label">{{ item.value3 }}</span>
          <span class="phone-name phone-text" v-if="item.value1">{{ item.value1 }}</span>
          <span class="phone-text">{{ item.value2 }}</span>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { permissionMixin } from "@/mixin";
import Title from "@/components/Title/Title.vue";
import HomeFooter from "@/components/HomeLast/HomeFooter.vue";
import { mapState } from "vuex";

export default {
  name: "Layout",
  components: {
    Title,
    HomeFooter,
  },
  mixins: [permissionMixin],
  computed: {
    ...mapState({
      homeData: (state) => {
        return state.home.homeData;
      },
      YwhzrxData: (state) => {
        return state.home.YwhzrxData;
      },
      loading: (state) => {
        return state.loading;
      },
    }),
    uploadurl: function() {
      return process.env.VUE_APP_API_BASE_URL + "api/File/UploadLicense";
    },
  },
  mounted() {
    this.fetch();
    this.fetchYwhzrx();
  },
  data() {
    return {
      noneBtnImg: false,
      phoneVisible: false,
      complaSuggesVisible: false,
      imageUrl: "",
      fileList: [],
      limitCountImg: 3,
      complaSuggesForm: {
        SuggestionsType: 0,
        CreatorUserId: 0,
        Title: "",
        SuggestionsToFiles: [],
        Content: "",
      },
      scroll: 0,
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
    };
  },
  watch: {
    $route(to, from) {
      if (to.path !== from.path) {
        document.querySelector("#mainContainer").scrollTop = 0;
      }
    },
  },
  methods: {
    fetch() {
      this.$store.dispatch("home/queryHomeData").then(() => {});
      this.$store.dispatch("home/queryDashboardData");
    },
    fetchYwhzrx() {
      this.$store.dispatch("home/fetchYwhzrx")
    },
    handleScroll() {
      this.scroll =
        document.documentElement.scrollTop || document.body.scrollTop;
    },
    handleAvatarSuccess(res) {
      this.$refs.complaSuggesForm.clearValidate("SuggestionsToFiles");
      this.complaSuggesForm.SuggestionsToFiles.push(res.result);
    },
    handleAvatarSuccessChange(file, fileList) {
      this.fileList = fileList;
      this.noneBtnImg = fileList.length >= this.limitCountImg;
    },
    showComplaSuggesVisible() {
      this.queryPermis(() => {
        this.complaSuggesVisible = !this.complaSuggesVisible;
      }, true);
    },
    hideComplaSuggesVisible() {
      this.complaSuggesVisible = false;
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isLt2M;
    },
    suggesFormSubmit() {
      this.$refs["complaSuggesForm"].validate(() => {
        // if (this.complaSuggesForm.SuggestionsToFiles.length <= 0) {
        //   this.$message.warning("请上传反馈图片");
        //   return;
        // }
        if (
          this.complaSuggesForm.SuggestionsToFiles.length > 0 &&
          this.complaSuggesForm.Title !== "" &&
          this.complaSuggesForm.Content !== ""
        ) {
          this.$store
            .dispatch("home/createSuggestions", this.complaSuggesForm)
            .then(() => {
              this.$message.success("反馈成功");
              this.hideComplaSuggesVisible();
            });
        }
      });
    },
    handleRemove(file) {
      console.log(this.fileList, file);
      return this.$confirm(`确定移除 ${file.name}？`).then(() => {
        this.$message({
          type: "success",
          message: "删除成功!",
        });
        this.complaSuggesForm.SuggestionsToFiles = this.complaSuggesForm.SuggestionsToFiles.filter(
          (item) => item.fileId !== file.response.result.fileId
        );
        this.fileList = this.fileList.filter((item) => item.uid !== file.uid);
      });
    },
    onClikcPhone() {
      this.phoneVisible = true;
    },
    handlePictureCardPreview(file) {
      console.log(file, "zxx", this.fileList);

      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    popoverPublic() {
      this.complaSuggesForm = {
        SuggestionsType: 0,
        Title: "",
        SuggestionsToFiles: [],
        Content: "",
      };
      this.noneBtnImg = false;
      this.fileList = [];
      this.$refs["complaSuggesForm"].resetField &&
        this.$refs["complaSuggesForm"].resetField();
      this.$refs["complaSuggesForm"].clearValidate();
    },
  },
};
</script>

<style lang="less" scoped>
@import url("../../assets/css/popover.less");
.RightImg {
  width: 41.7px;
  height: 44.2px;

  img {
    width: 41.7px;
    height: 44.2px;
    object-fit: cover;
  }
}

/deep/.phone-dialog{
  border-radius: 16px;
  width: 560px;
  height: 400px;
  .el-dialog__body{
    padding-top: 0;
  }
}
.phone {
  border-radius: 16px;
  padding-left: 65px;
  .phone-line {
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #343434;
    line-height: 55px;
  }
  .phone-text {
    color: #ff6600;
    font-weight: 600;
  }
  .phone-label {
    display: inline-block;
    width: 140px;
  }
  .phone-name {
    width: 100px;
    display: inline-block;
  }
}
.app-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.main-container {
  flex: 1;
  -webkit-box-flex: 1;
  overflow: hidden;
}
.router-content {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}
</style>
