<template>
  <div class="top" id="top">
   <div class="header">
		 <div class="logo" @click="goHome()">
      <div class="logo-image">
        <img src="/img/homeIcon/logo@2x.png" alt="" />
      </div>
      <!-- <div style="margin: 10px 0 0 10px;">
        <p class="p1">陕西省报废汽车回收拆解有限公司</p>
        <p class="p2">SHAANXI PROVINCE SCRAPPED AUTOMOBILE RECYCLING CO.LTD</p>
      </div> -->
    </div>
    <div class="login">
      <div class="first">
        <span style="padding-right: 18px;">联系电话：029-84979869</span>
        <span @click="goCar" class="shopCar" style="position: relative;">
          <img src="/img/homeIcon/shop.png" />
          <span>购物车</span>
          <div v-if="name !== '' && resCount > 0" class="shopCount">
            <span
              :class="{ fontScale: resCount > 99, fontScale8: resCount > 9 }"
              >{{ resCount > 99 ? "99+" : resCount }}</span
            >
          </div>
        </span>
        <span class="userSetUp" v-if="name !== ''">
          <el-dropdown placement="bottom-start">
            <span class="el-dropdown-link">
              <img :src="userPhoto" alt="" />{{ name }}
            </span>
            <el-dropdown-menu slot="dropdown" class="user-dropdown">
              <el-dropdown-item @click.native="userInfo"
                >个人中心</el-dropdown-item
              >
              <el-dropdown-item @click.native="out">退出</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </span>
        <!-- <span v-if="login" @click="Login">
          <span style="padding-right: 3px;color: #2CAD7B;">
            <i
              class="el-icon-user"
              style="margin-right: 5px;color: #2CAD7B;"
            ></i
            >登录</span
          >
          <span style="padding-right: 4px;color: #2CAD7B;">/</span>
          <span style="color: #2CAD7B;">注册</span>
        </span> -->
        <span v-if="name == ''">
          <span
            @click="Login"
            style="padding-right: 3px;color: #29AC79;cursor: pointer;"
          >
            <i
              class="el-icon-user"
              style="margin-right: 5px;color: #29AC79;"
            ></i
            >登录</span
          >
          <span style="padding-right: 4px;color: #29AC79;">/</span>
          <span @click="register" style="color: #29AC79;cursor: pointer;"
            >注册</span
          >
        </span>
      </div>
			<div class="search-keywords">
				<el-input
					v-model="searchValue"
					class="search-keywords-input"
					placeholder="关键词搜索......"
					size="small"
					@keyup.enter.native="onClickSearch"
					clearable>
					<el-button @click="onClickSearch" class="search-keywords-button" slot="append">搜索</el-button>
				</el-input>
			</div>
    </div>
	 </div>
    <LayoutSidebar
      :index="changeSelectStyle"
    />
		<!-- <div class="nav">
			<div class="nav-w">
				<div
					:class="{
						'title-item': true,
						changeStyle: `${changeSelectStyle}` === `${item.id}`
					}"
					@click="changeStyle(item.id, item)"
					v-for="(item, index) in imgBox"
					:key="index"
				>
					<span alt="">{{ item.title }}</span>
				</div>
			</div>
		</div> -->
  </div>
</template>

<script>
import request from "@/utils/request";
import Cookies from "js-cookie";
import { mapState } from "vuex";
import LayoutSidebar from './sidebar/index.vue';
import { getToken, getUserInfo } from "@/utils/cookies";
export default {
  name: "adressMaintain",
  components: {
    LayoutSidebar
  },
  data() {
    return {
      userDate: "",
      login: "",
      name: "",
      userPhoto: "",
			searchValue: '',
      changeSelectStyle: undefined,
      imgBox: [
        {
          title: "首页",
					id: 1
        },
        {
          title: "零配件销售",
					id: 2
        },
        {
          title: "大宗商品销售",
					id: 3
        },
        {
          title: "新车交易",
					id: 4
        },
        {
          title: "二手车交易",
					id: 5
        },
        {
          title: "废旧物资回收",
					id: 6
        },
        // {
        //   title: "新闻"
        // },
        // {
        //   title: "关于我们"
        // }
      ]
    };
  },
  // 通过computed计算属性及时改变
  computed: {
    ...mapState({
      resCount: state => {
        return state.resCount;
      }
    })
  },
  watch: {
    $route(to, from) {
      if (to.name !== 'login' && to.path !== from.path) {
        this.userDate = getUserInfo();
        if (this.userDate && this.userDate.userId) {
          this.name = this.userDate.name;
          this.userPhoto = this.userDate.userPhoto;
        }
      } else {
        this.userDate = "";
      }
      console.log(this.userDate, this.name, 'this.userDate')
			if(from.name === 'successfulTrade'){
				this.$store.dispatch("getShoppingCartCount");
			}
      let pathname = to.path
      let pathArr = pathname.split('/')
      let currentPage = pathArr[pathArr.length - 2]
      this.changeSelectStyle = currentPage || 'home'
    },
    cartCount: {
      handler(n, o) {
        console.log(n, o);
      },
      deep: true
    }
  },
  created() {
    if (Cookies.get("userInfo") !== undefined) {
      this.userDate = JSON.parse(Cookies.get("userInfo"));
      this.name = this.userDate.name;
      this.userPhoto = this.userDate.userPhoto;
    }
    let pathname = this.$route.path
    let pathArr = pathname.split('/')
    let currentPage = pathArr[pathArr.length - 2]
    this.changeSelectStyle = currentPage || 'home';
    if (this.changeSelectStyle == undefined) {
      this.changeSelectStyle = "";
    }
    // console.log(this.changeSelectStyle);
  },
  updated() {
    // console.log(getUserInfo(), "getUserInfo");
  },
  methods: {
		onClickSearch() {
			if(!this.searchValue){
				this.$message.warning("请输入关键词");
				return
			}
      let routeUrl = this.$router.resolve({
        path: '/news/search-news',
				query: {
					index: 'search',
					FindKeyWords: this.searchValue
				}
      });
      window.open(routeUrl.href, "_blank");
    },
    Login() {
      this.$router.push({
        path: "/register/login"
      });
    },
    register() {
      this.$router.push({
        path: "/register/register"
      });
    },
    goHome() {
      this.$router.push({
        path: "/"
      });
    },
    out() {
      // this.$store.dispatch('user/logout');
      const token = getToken();
      // console.log(token)
      this.$confirm("是否确认注销登录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        callback: action => {
          if (action === "confirm") {
            console.log("按下 确定");
            request({
              url: "/TokenAuth/Logout",
              method: "post",
              // data: [],
              headers: {
                Authorization: "Bearer " + token
              }
            })
              .then(response => {
                // console.log(response.result)
                if (response.result) {
                  Cookies.remove("userInfo");
                  Cookies.remove("token");
                  this.$message.success("成功");
                  this.$router.push({
                    path: "/register/login"
                  });
                  this.shopCount = 0;
                  window.sessionStorage.setItem("token", "");
                  window.sessionStorage.setItem("isLogin", false);
                  window.sessionStorage.setItem("userInfo", JSON.stringify({}));
                } else {
                  this.$message.error("失败");
                }
              })
              .catch(error => {
                //	console.log(error)
              });
          } else {
            //	console.log('按下 取消')
          }
        }
      });
      // this.$confirm('是否确认要删除?', '提示',{
      //                 confirmButtonText: '确定',
      //                 cancelButtonText: '取消',
      //                 type: 'warning'
      //             })
    },
    changeStyle(index) {
      this.changeSelectStyle = index;
      if (this.changeSelectStyle == 0) {
        this.$router.push({
          path: "/",
          query: {
            index: index
          }
        });
      }
      if (this.changeSelectStyle == 1) {
        this.$router.push({
          path: '/home',
          query: {
            index: index
          }
        });
      }
      if (this.changeSelectStyle == 2) {
        this.$router.push({
          path: "/sparePartsMall/sparePartsMall",
          query: {
            index: index
          }
        });
      }
      if (this.changeSelectStyle == 3) {
         this.$router.push({
          path: "/bulkCommodity/bulkCommodity",
          query: {
            index: index
          }
        });
      }
      if (this.changeSelectStyle == 4) {
        this.$router.push({
          path: "/new-car-trading/image-list",
          query: {
            index: index
          }
        });
      }
      if (this.changeSelectStyle == 5) {
        this.$router.push({
          path: "/used-car-trading/image-list",
          query: {
            index: index
          }
        });
      }
      if (this.changeSelectStyle == 6) {
        this.$router.push({
          path: "/recycling-waste/list",
          query: {
            index: index
          }
        });
      }
    },
    userInfo() {
      this.$router.push({
        path: "/personalCenter/personalCenter"
      });
      // this.changeSelectStyle = ''
    },
    goCar() {
      this.$router.push({
        path: "/shoppingCar/shoppingCar"
      });
			this.$store.dispatch("getShoppingCartCount");
    }
  }
};
</script>

<style lang="less" scoped>
.header {
	position: relative;
	max-width: 1200px;
	width: 93%;
  align-items: flex-end;
	margin: 0 auto;
  display: flex;
  justify-content: space-between;
  background-color: white;
	padding: 10px 0;
	.logo-image{
		width: 100%;
		display: flex;
		align-items: center;
		align-self: center;
		justify-content: center;
		float: left;
		img{
			width: 100%;
		}
	}
  .logo {
    display: flex;
    cursor: pointer;
		width: 50%;
    .p1 {
      font-size: 32px;
      font-family: SourceHanSerifSC;
      font-weight: 800;
      color: #000000;
      line-height: 48px;
    }

    .p2 {
      font-size: 14px;
      font-family: OPPOSans;
      font-weight: normal;
      color: #5e6a7c;
      line-height: 15px;
      letter-spacing: 0.75px;
    }
  }

  .login {
    font-size: 14px;
    color: #7a8391;
		width: 32%;
		margin-left: 10%;
    .first {
			margin-top: 8px;

      > span:nth-child(1) {
        border-right: 1px solid rgba(172, 182, 198, 0.5);
        float: left;
      }

      .shopCar {
        cursor: pointer;
        padding-left: 18px;
        padding-right: 10px;
        float: left;

        > img {
          width: 20px;
          margin-right: 5px;
          float: left;
        }
      }

      .userSetUp {
        cursor: pointer;
        margin-left: 10px;
        line-height: 24px;
        position: relative;
        padding-left: 24px;

        img {
          position: absolute;
          left: -30px;
          top: 0;
          width: 24px;
          height: 24px;
          border-radius: 50%;
          vertical-align: middle;
          margin-bottom: 3px;
          margin-right: 6px;
        }
      }
    }

    .second {
      margin-right: 220px;

      // a:hover {
      // 	background: #cbf6f8;
      // 	border-bottom: solid #2EC4CD 1px;

      // 	span {
      // 		color: #033B8D;
      // 	}
      // }

      .title-item {
        display: inline-block;
        text-decoration: none;
        color: #333333;
        padding: 15px 24px;
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        cursor: pointer;
        margin-right: 5px;
      }

      .title-item:hover {
        span {
          &:extend(.changeStyle span);
        }
      }
    }
  }
	.search-keywords{
		width: 100%;
    float: right;
    position: relative;
    font-size: 0;
    margin-top: 8px;
	}
	/deep/.search-keywords-input{
		>.el-input__inner {
			border: 1px solid rgb(167 167 167);
		}
		>.el-input-group__append{
			// width: 20%;
			background: rgb(255 102 0);
			color: rgb(255 255 255);
			border: none;
		}
	}
}

.shopCount {
  text-align: center;
  position: relative;
  bottom: 5px;
  right: 3px;
  display: inline-block;
  width: 18px;
  height: 18px;
  line-height: 18px;
  background-color: #f2412a;
  color: white;
  font-size: 12px;
  border-radius: 50%;
  .fontScale {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    display: inline-block;
    transform: scale(0.6);
  }
  .fontScale8 {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    display: inline-block;
    transform: scale(0.8);
  }
}

.user-dropdown {
  margin-top: 5px !important;
  padding: 4px !important;
  color: #30af7d;
  font-size: 14px;

  /deep/ .popper__arrow {
    border-bottom-color: #1ebef4 !important;
    left: 50% !important;
    display: none;
  }

  .el-dropdown-menu__item {
    padding: 0 30px;
  }

  .el-dropdown-menu__item:not(.is-disabled):hover {
    background: #eff6f6 !important;
    border-radius: 5px !important;
    color: #30af7d;
  }
}

.nav{
	background-color: #032e81;
	color: #ffffff;
	text-align: center;
	width: 100%;
	.nav-w{
		width: 93%;
		max-width: 1200px;
		overflow: hidden;
		display: flex;
		margin: 0 auto;
	}
	.title-item{
		max-width: 16.6%;
		font-size: 18px;
		line-height: 60px;
		flex: 1;
		cursor: pointer;
		display: inline-block;
		&.changeStyle{
			background-color: #ff6600;
		}
	}
}

// .changeStyle {
//   // background-color: #cbf6f8;
//   border-bottom: solid #2ec4cd;
//   display: inline-block;
//   text-decoration: none;
//   background: linear-gradient(180deg, #fff, #e4f4f5 50%);

//   // background-image:-webkit-linear-gradient(top,#181A7D,#30C9CD,#5BB960);
//   // -webkit-background-clip:text;
//   // -webkit-text-fill-color:transparent;
//   span {
//     background: linear-gradient(
//       180deg,
//       #181a7d 0%,
//       #30c9cd 71.4%,
//       #5bb960 100%
//     );
//     font-weight: bold;
//     background-clip: text;
//     -webkit-background-clip: text;
//     -webkit-text-fill-color: transparent;
//   }
// }
</style>
