<template>
	<div class="footer">
		<div class="contact-details">
			<div class="contact-details-link">
				<div class="contact-link-item">
					<div class="contact-link-icon">
						<img src="/img/homeIcon/hotline@2x.png" alt="" />
					</div>
					<div class="contact-link-text">
						<div class="contact-link-title">合作热线</div>
						<div class="contact-link-url">029-84979856</div>
						<!-- <div class="contact-link-url">029-84979863</div>
						<div class="contact-link-url">029-84979869</div> -->
					</div>
				</div>
				<div class="contact-link-item">
					<div class="contact-link-icon">
						<img src="/img/homeIcon/phone@2x.png" alt="" />
					</div>
					<div class="contact-link-text">
						<div class="contact-link-title">监督举报</div>
						<!-- <div class="contact-link-url">029-84979881</div> -->
						<div class="contact-link-url">029-84979886</div>
						<div class="contact-link-url">sqcjwzhs@126.com</div>
					</div>
				</div>
				<div class="contact-link-item">
					<div class="contact-link-icon">
						<img src="/img/homeIcon/email@2x.png" alt="" />
					</div>
					<div class="contact-link-text">
						<div class="contact-link-title">工作邮箱</div>
						<div class="contact-link-url">sxsqccj@126.com</div>
					</div>
				</div>
				<div class="contact-link-item">
					<div class="contact-link-icon">
						<img src="/img/homeIcon/link@2x.png" alt="" />
					</div>
					<div class="contact-link-text">
						<div class="contact-link-title">友情链接</div>
						<div class="contact-link-url">http://www.sxs56.com/</div>
					</div>
				</div>
			</div>
			<div class="contact-details-orcode">
				<div class="details-orcode">
					<img src="/img/homeIcon/link1.png" alt="" />
					<span>扫码关注陕物流</span>
				</div>
				<div class="details-orcode fr">
					<img src="/img/homeIcon/link2.png" alt="" />
					<span>扫码关注汽车拆解公司</span>
				</div>
			</div>
		</div>
		<div class="footer-bottom">
			<div class="footer-logo">
				<img src="/img/homeIcon/logo@2x.png" alt="" />
			</div>
			<div class="footer-info">
				<div class="footer-info-text">邮政编码：710300 邮箱：sxscdqb@126.com 公司地址：陕西省西安市鄠邑区沣京工业园沣五东路</div>
				<div class="footer-info-text">Copyright© 2014-{{ year }} All Rights Reserved. 陕西省报废汽车回收拆解有限公司 版权所有 <br />技术支持  西安文思畅想软件科技有限责任公司 <span class="integrated" @click="onClickOpen('https://beian.miit.gov.cn/#/Integrated/index')">陕ICP备16014236号-1 </span> </div>
				<div class="footer-electronic">
					<a id="imgurl" href="https://zzlz.gsxt.gov.cn/businessCheck/verifKey.do?showType=p&serial=916100000881352084-SAIC_SHOW_1000009161000008813520841668145418632&signData=MEUCIQC5FR8FTvn+TvZYgdE7KvFEv5KPhX1n6FzefwK6YB2RmwIgRnlz4CTn+N86FGIYiJT2Uo+Yu2bFPNn2tOt6fs8C2DM=" title="电子营业执照" target="_blank"><img src="/img/homeIcon/zhizhao.png" width="62" height="62" border="0"></a >
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import moment from 'moment'
export default {
  name: "Footer",
  data() {
    return {
      hotConsultFigure: {
        timer: null,
        count: 0,
        lastCount: 0,
      },
			year: moment().format('YYYY')
    };
  },
  methods: {
    onClickMore(url) {
      this.$router.push({
        path: url,
      });
    },
		onClickOpen(path) {
      window.open(path, "_blank");
    }
  },
};
</script>

<style lang="less" scoped>
.footer {
	box-sizing: border-box;
	text-align: center;
	padding-top: 70px;
	.contact-details{
		text-align: left;
		width: 93%;
		max-width: 1200px;
		margin: 0 auto;
		overflow: hidden;
		display: flex;
		align-items: center;
		.contact-details-link{
			width: 75%;
			overflow: hidden;
			.contact-link-item{
				width: 24.5%;
				float: left;
				overflow: hidden;
				display: flex;
				.contact-link-icon{
					width: 25%;
					margin-right: 3%;
					img{
						width: 100%;
					}
				}
				.contact-link-text{
					width: 76%;
					font-size: 18px;
					color: #333333;
					.contact-link-url{
						color: #FF6600;
						font-size: 16px;
						color: rgb(255 102 0);
						font-weight: bold;
						word-break: break-word;
						text-align: left;
					}
				}
			}
		}
		.contact-details-orcode{
			width: 22%;
			overflow: hidden;
			.details-orcode{
				width: 47%;
				float: left;
				text-align: center;
				color: #333333;
				img{
					width: 100%;
					height: 100%;
				}
				&.fr{
					float: right;
				}
			}
		}
	}
	.footer-logo{
		padding-top: 40px;
		display: inline-block;
		width: 32%;
		max-width: 680px;
		img{
			width: 100%;
		}
	}
	.footer-bottom{
		margin-top: 75px;
		background: rgb(238, 238, 238);
		padding-bottom: 40px;
	}
	.footer-info{
		font-size: 14px;
		padding-top: 16px;
		.footer-info-text{
			padding-top: 4px;
			color: #666666;
		}
	}
	.integrated{
		cursor: pointer;
	}
	.footer-electronic{
		padding-top: 10px;
	}
}
</style>
