<template>
  <el-menu-item :index="item.menuId" v-if="!item.children">
    <app-link :to="resolvePath(item)">
      <span>{{ item.name }}</span>
    </app-link>
  </el-menu-item>
  <el-submenu
    v-else
    :index="item.menuId"
    :popper-append-to-body="false"
    :item="item"
    :title="item && item.name"
  >
    <template slot="title">{{ item.name }}</template>
    <sidebar-item
      v-for="subItem in item.children"
      :key="subItem.key"
      :isRoundDots="true"
      :item="subItem"
      class="nest-menu"
    ></sidebar-item>
  </el-submenu>
</template>

<script>
import AppLink from "./link.vue";
import SidebarItem from "./sidebar-item.vue";

export default {
  name: "SidebarItem",
  components: {
    SidebarItem,
    AppLink,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    isRoundDots: {
      type: Boolean,
      default: false,
    },
    basePath: {
      type: String,
      default: "",
    },
  },
  methods: {
    resolvePath(menu) {
      const { theUri, isExternal, target } = menu;
      let result = "";
      // 外链
      if (isExternal === 1) {
        // 在新窗口打开
        if (target === "_blank") {
          result = theUri;
        } else {
          result = "/external?url=" + theUri;
        }
      } else {
        // &page=${menu.menuId}
        result = `${theUri}`;
      }
      return result;
    },
  },
};
</script>
<style lang="less"></style>
